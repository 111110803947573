.sticky_col {
    position: -webkit-sticky;
    position: sticky;
    z-index: 9999;
    left:0;
    background-color: white;
}

.places-container {
    width: 300px;
    border: 1px solid #ccc;
}
.map-container {
    width: 100%;
    height: 100vh;
}

.combobox-input {
    width: 100%;
    padding: 0.5rem;
}

.marker-label {
    background-color: rgb(237, 125, 49);
    padding: 5px 10px;
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: 600;
    border-radius: 5px;
    bottom: 28px;
    left: 0;
    position: relative;
}

.disabled-dropdown {
    pointer-events: none;
    color: gray;
}