@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .custom-scrollbar::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
    background-color: #f5f5f5;
  }
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: darkgray;
    /*border: 2px solid grey;*/
  }
  .main-data-grid .react-data-grid {
    background-color: #fff;
  }
  .custom-scrollbar-dark::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
    background-color: rgb(32 35 42 / 1);
  }
  .custom-scrollbar-dark::-webkit-scrollbar {
    width: 6px;
    background-color: rgb(32 35 42 / 1);
  }
  .custom-scrollbar-dark::-webkit-scrollbar-thumb {
    background-color: #374151;
    /*border: 2px solid grey;*/
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1rem;
  white-space: nowrap;
}

.divider:not(:empty) {
  gap: 1rem;
}

.divider:before {
  background-color: #f0f0f0;
  --tw-bg-opacity: .1;
}

.divider:after {
  background-color: #f0f0f0;
  --tw-bg-opacity: .1;
}

.divider:before, .divider:after {
  content: "";
  flex-grow: 1;
  height: 0.125rem;
  width: 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  top: 150%;
  left: 50%;
  margin-left: -60px;
  z-index: 9999;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

aside.ribbon-banner {
  display: block;
  position: fixed;
  bottom: 0px;
  right: -35px;
  width: 120px;
  height: 50px;
  /*line-height: 25px;*/
  padding-top: 10px;
  text-align: center;
  opacity: 0.7;
  transform: rotate(-45deg);
  font-size: 10px;
  font-weight: 600;
  color: white;
  background-color: red;
  border: 1px solid white;
  z-index: 500;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.map-container {
    position: relative;
    width: 100%;
    height: 80vh !important;
}

.loading-circle-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.loading-screen-blur {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  backdrop-filter: saturate(180%) blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .lg\:divider-horizontal {
    flex-direction: column;
  }

  .lg\:divider-horizontal {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    height: auto;
    width: 1rem;
  }

  .lg\:divider-horizontal:before {
    height: 100%;
    width: 0.125rem;
  }

  .lg\:divider-horizontal:after {
    height: 100%;
    width: 0.125rem;
  }
}